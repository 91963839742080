import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  message,
  Select,
  Space,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import useChemicalLicenseList from "../../hooks/useChemicalLicenseList";
import NetworkApi from "../../network/networkApi";
interface Props {
  visible: boolean;
  onHide: () => void;
}
const WarehouseChemicalAdd = ({ visible, onHide }: Props) => {
  const formRef = useRef<any>();
  const [selectedChemicalType, changeSelectedChemicalType] = useState<any>("");
  const [remaining, changeRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [licenseList, _loadLicense, licenseFilter] = useChemicalLicenseList();
  useEffect(() => {
    if (visible) {
      formRef.current?.resetFields();
      licenseFilter({
        status: "active",
        type: ["import", "importsale"],
      });
    }
  }, [visible]);
  const onFinish = useCallback(async (value: any) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (value.importDate) {
        value.importDate = value.importDate.format("YYYY-MM-DD");
      }
      let response = await NetworkApi.requestPost(
        "/api/warehouse/chemical/import",
        value
      );
      if (response.resultCode == 200) {
        onHide();
      } else {
        message.error(response.message || "Алдаа гарлаа");
      }
    } catch (error: any) {
      message.error(error?.message || "Алдаа гарлаа");
    } finally {
      setIsLoading(false);
    }
  }, []);
  return (
    <Modal
      onCancel={onHide}
      visible={visible}
      title={"Бүтээгдэхүүн нэмэх"}
      footer={null}
    >
      <Form layout="vertical" ref={formRef} onFinish={onFinish}>
        <Form.Item
          name="licenseId"
          label="Зөвшөөрөл:"
          rules={[
            {
              required: true,
              message: "Зөвшөөрөл сонгоно уу!",
            },
          ]}
        >
          <Select
            onSelect={(v) => {
              const license = licenseList.result.find((item) => item.id == v);
              changeSelectedChemicalType(license?.chemical?.type);
              changeRemaining(license?.remaining);
            }}
          >
            <Select.Option value="">Зөвшөөрөл</Select.Option>
            {licenseList.result.map((t) => (
              <Select.Option value={t.id}>
                {t.chemical?.technicalName}-{t.remaining}
                {t.chemical?.unit?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="importDate"
          label="Импортлосон/Үйлдвэрлэсэн огноо:"
          rules={[
            {
              required: true,
              message: "Импортлосон/Үйлдвэрлэсэн огноо оруулна уу!",
            },
          ]}
        >
          <DatePicker placeholder={"Огноо"} />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Бодис, бордооны хэмжээ:"
          rules={[
            {
              required: true,
              message: "Бодис, бордооны хэмжээ оруулна уу!",
            },
            () => ({
              validator(rule, amount) {
                if (typeof amount === "number" && amount <= 0) {
                  return Promise.reject("0-ээс дээш хэмжээ оруулна уу");
                }
                if (
                  typeof amount === "number" &&
                  selectedChemicalType &&
                  amount > remaining
                ) {
                  return Promise.reject(
                    `Зөвшөөрлийн үлдэгдлээс хэтэрсэн байна`
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            parser={(value) => value?.replace(",", ".") || ""}
            addonAfter="кг"
          />
        </Form.Item>
        {selectedChemicalType?.hasParcel && (
          <Form.Item
            name="parcel"
            label="Сав баглааны хэмжээ:"
            rules={[
              {
                required: true,
                message: "Сав баглааны хэмжээ оруулна уу!",
              },
            ]}
          >
            <InputNumber
              parser={(value) => value?.replace(",", ".") || ""}
              addonAfter="ш"
            />
          </Form.Item>
        )}
        <Space size={16}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Хадгалах
          </Button>
          <Button onClick={() => onHide()}>Болих</Button>
        </Space>
      </Form>
    </Modal>
  );
};
export default WarehouseChemicalAdd;
