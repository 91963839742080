import { CloudSyncOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useCallback, useState } from "react";
import NetworkApi from "../../../network/networkApi";

type Props = {
  id: string;
  loadContent?: () => void;
};

export const IncentiveReference = ({ id, loadContent }: Props) => {
  const [isReferencing, changeIsReferencing] = useState<boolean>(false);

  const reference = useCallback(async () => {
    changeIsReferencing(true);
    try {
      let response = await NetworkApi.requestPost(
        `/api/incentive/status/reference`,
        { id }
      );
      if (response.resultCode === 200) {
        message.success("Амжилттай");
        loadContent?.();
      } else {
        message.error(response.message || "Алдаа гарлаа.");
      }
    } catch (e) {
      message.error("Алдаа гарлаа.");
    } finally {
      changeIsReferencing(false);
    }
  }, [id]);

  return (
    <Button
      type={"primary"}
      size={"small"}
      icon={<CloudSyncOutlined />}
      onClick={() => reference()}
      loading={isReferencing}
      disabled={isReferencing}
    />
  );
};
